import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgTransitionCover from "../../assets/images/journey/transition/transition-bg.png"

const CruisePage = () => (
  <Layout>
    <SEO title="Transition Phase" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #880C0C"}}>
        <img src={imgTransitionCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                    TRANSITION PHASE
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  The next stage in the Hope Probe’s journey is the transition from the Capture Orbit to an acceptable Science Orbit in preparation for its primary science operations.
                  </p>
                  <p>
                  The Capture Orbit – an elliptical orbit lasting 40 hours, will take the Hope Probe as close as 1,000 km above Mars’ surface and as far as 49,380 km away from it. 
                  </p>
                  <p>
                  In the Capture Orbit, the first image of Mars will be taken and transmitted to the MOC. Daily contact is then scheduled with the team back on Earth, enabling a quick turn-around of command sequence uploads and telemetry receipt.
                  </p>
                  <p>
                  After a period of testing and validation, spanning nearly 6 weeks from the Mars Orbit Insertion phase, the Hope Probe will move into the Science Orbit.
                  </p>
                  <p>
                  The Hope Probe will have an elliptical Science Orbit ranging between 20,000–43,000 km, with one complete orbit taking 55 hours. The mission’s highly innovative orbit is unique and will allow the Hope Probe to complete the first ever planet-wide, 24x7 picture of Mars’ atmospheric dynamics and weather.
                  </p>
                  <p>
                  The contact period with the MOC is limited to 6-8 hours, twice a week. During this time, it is expected that Hope Probe will transfer over 1 TB of novel data on Mars’ atmosphere and its dynamics.
                  </p>
                </div>
            </div>  
                 
          </div>  
      </section>
    </main>
  </Layout>
)

export default CruisePage
